/**
 * visualizing numbers
 * @param numStr
 * @param point
 * @returns {string}
 */
export function formatNum(numStr: string | undefined, point = 4): string | undefined {
  if (!numStr) return numStr
  const str = String(numStr)
  const num = parseFloat(str)

  if (num >= 1) {
    return num.toFixed(point).replace(/\.?0+$/, '')
  }

  const decimal = str.split('.')[1] || ''
  if (decimal.length <= 3) return str

  const leadingZeroes = decimal.split('').findIndex(char => char !== '0')
  if (leadingZeroes <= 3) {
    return parseFloat(str).toFixed(point).replace(/\.?0+$/, '')
  }

  const subscriptDigits = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉']
  const subscriptNumber = leadingZeroes.toString().split('').map(digit => subscriptDigits[parseInt(digit)]).join('')

  return `0.0${subscriptNumber}${decimal.substring(leadingZeroes, leadingZeroes + point - subscriptNumber.length)}`
}

export async function batchRequest(list: any[], fun = null, batchSize = 5) {
  let results = []

  for (let i = 0; i < list.length; i += batchSize) {
    const batch = list.slice(i, i + batchSize)
    // eslint-disable-next-line no-await-in-loop
    const batchResults = await Promise.all(fun ? batch.map(item => fun(item)): batch)
    results = results.concat(batchResults)
  }

  return results
}

/**
 * @description 根据模板格式化时间戳
 * @author lecoler
 * @date 2022/12/14
 * @param {String} fmt - 模板格式
 * @param {String|Date|Number} data - 时间戳/时间
 * @return String
 */
export function dateFormat(fmt, data) {
  let date;
  if (data instanceof Date) {
    date = data;
  } else if (String(data).length >= 13) {
    date = new Date(data);
  } else {
    const num = 13 - String(data).length;
    date = new Date(Number(data) * (10 ** num));
  }

  let ret;
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  // eslint-disable-next-line guard-for-in
  for (const k in opt) {
    ret = new RegExp(`(${  k  })`).exec(fmt);
    if (ret) {
      // eslint-disable-next-line no-param-reassign
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'),
      );
    }
  }
  return fmt;
}